import React, { useState } from "react";

import {
  Text,
  HStack,
  Pressable,
  PresenceTransition,
  ScrollView,
  Box,
  Center,
  View,
  Stagger,
  useColorMode,
  useMediaQuery,
  useBreakpointValue,
} from "native-base";
import Pomodoro from "../Projects/Pomodoro/src/Pomodoro";
import { Conways } from "../Projects/Conways/src/Conways";
import pomo from "../assets/lottie/pomo.json";
import { SlideAnimation } from "../components/SlideAnimation";
import { Wheel } from "../components/Wheel";
import { breakpoints } from "../theme";

const data = [
  {
    id: 1,
    title: "Pomodoro",
    description: "a much needed app for personal use that keeps me focused!",
    year: 2023,
    demoData: {
      app: (closeApp, viewCode) => (
        <Pomodoro closeApp={closeApp} viewCode={viewCode} />
      ),
      splashAnim: pomo,
      fileTree: [
        {
          name: "Pomodoro",
          defaultOpen: true,
          children: [
            {
              name: "src",
              defaultOpen: true,
              children: [
                {
                  name: "components",
                  children: [
                    { name: "HeaderBar.js" },
                    { name: "TaskList.js" },
                    { name: "TaskCard.js" },
                    { name: "AlertOverlay.js" },
                    { name: "ChoiceModal.js" },
                    { name: "NavWidget.js" },
                    { name: "NewTask.js" },
                    { name: "OnBoarding.js" },
                  ],
                },
                {
                  name: "screens",
                  children: [
                    { name: "TaskScreen.js" },
                    { name: "TimerScreen.js" },
                  ],
                },
                { name: "Pomodoro.js" },
                { name: "Readme.md" },
              ],
            },
          ],
        },
      ],
    },
  },

  {
    id: 2,
    title: "Conways",
    description: "Cellular automata thingy",
    year: 2023,
    demoData: {
      app: (closeApp, viewCode) => (
        <Conways closeApp={closeApp} viewCode={viewCode} />
      ),
      splashAnim: pomo,
      fileTree: [
        {
          name: "Conways",
          defaultOpen: true,
          children: [
            {
              name: "src",
              defaultOpen: true,
              children: [
                { name: "Conways.js" },
                { name: "GridOld.js" },
                { name: "GridRevised.js" },
                { name: "Readme.md" },
              ],
            },
          ],
        },
      ],
    },
  },

  {
    id: 3,
    title: "Sixr",
    description: "bSJFNAKSNFAKJSNFAKJSNFAKJSFN",
    link: "https://sixrvr.netlify.com",
    year: 2019,
  },

  {
    id: 4,
    title: "Preem",
    description: "bSJFNAKSNFAKJSNFAKJSNFAKJSFN",
    link: "https://github.com/PreemFreelancePlatform/Preem-FE",
    year: 2019,
  },

  {
    id: 5,
    title: "Nexus.Node",
    description: "bSJFNAKSNFAKJSNFAKJSNFAKJSFN",
    link: "https://github.com/NexusEsportsPlatform/Nexus-BE",
    year: 2019,
  },
  {
    id: 6,
    title: "Nexus",
    description: "bSJFNAKSNFAKJSNFAKJSNFAKJSFN",
    link: "https://github.com/NexusEsportsPlatform/Nexus-FE",
    year: 2019,
  },

  {
    id: 7,
    title: "FishFriends",
    description: "bSJFNAKSNFAKJSNFAKJSNFAKJSFN",
    link: "https://5e17c55e7d016d0188b94241--fishfriends1.netlify.app/index.html",
    year: 2019,
  },
];

const WorkScreen = ({ navigate }) => {
  const { toggleColorMode, colorMode } = useColorMode();
  const [hoveredIndex, setHoveredIndex] = useState("");

  const [isMobile] = useMediaQuery({ maxWidth: breakpoints.lg });
  const [isPhone] = useMediaQuery({ maxWidth: breakpoints.md });
  const wheelSize = useBreakpointValue({
    base: "100",
    sm: "140",
    md: "225",
    lg: "250",
    xl: "275",
    xxl: "325",
  });

  const font = useBreakpointValue({
    base: "2.5em",
    sm: "2.7em",
    md: "3em",
    lg: "3em",
    xl: "2.3em",
    xxl: "2.3em",
  });

  const spacing = useBreakpointValue({
    lg: "30%",
    xl: "25%",
    xxl: "25%",
  });

  const handlePress = (item) => {
    if (item.demoData) {
      navigate("demo", item.demoData);
      colorMode === "light" && toggleColorMode();
    } else {
      window.open(item.link, "_blank");
    }
  };

  if (isPhone) {
    return (
      <ScrollView showsVerticalScrollIndicator={false} zIndex={-1} p="6%">
        <View py="4%" mt="6%" alignItems={"end"}>
          <Wheel size={wheelSize} fontSize={font} text={"Projects "} />
        </View>
        <Text
          alignSelf={"end"}
          opacity={0.6}
          fontSize={"1.5em"}
          fontFamily={"Light"}
        >
          {"[Featured Works]"}
        </Text>
        <Stagger
          style={{
            alignItems: "end",
            height: "9%",
          }}
          visible={true}
          initial={{
            opacity: 0,
            translateY: 100,
          }}
          animate={{
            translateY: 0,
            opacity: 1,

            transition: {
              type: "spring",
              damping: 100,
              stagger: {
                offset: 50,
              },
            },
          }}
        >
          {data.map((item, i) => (
            <Pressable onPress={() => handlePress(item, i)} key={item.id}>
              <ProjectCard item={item} i={i} hoveredIndex={hoveredIndex} />
            </Pressable>
          ))}
        </Stagger>
        <View h={"20%"} />
      </ScrollView>
    );
  }

  return (
    <HStack
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <Center flex={0.5} mt="23%">
        {/* <Box position={"absolute"}>
          {hoverData && !isMobile && <ProjectInfo hoverData={hoverData} />}
        </Box> */}

        <SlideAnimation
          visible={!isMobile}
          axis="y"
          from={40}
          to={0}
          speed={30}
        >
          <Wheel size={wheelSize} fontSize={font} text={"Projects "} />
        </SlideAnimation>
      </Center>
      <ScrollView
        position={"fixed"}
        top={0}
        right={isMobile ? "0" : "6%"}
        showsVerticalScrollIndicator={false}
        h={"100vh"}
        w="80%"
        p="2%"
      >
        {isMobile && (
          <View pt="25%" pb="3%" alignItems="end">
            <Wheel size={150} fontSize={font} text={"Projects "} />
          </View>
        )}
        <Text
          mt={!isMobile && spacing}
          alignSelf={"end"}
          opacity={0.6}
          fontSize={"1.5em"}
          fontFamily={"Light"}
        >
          {"[Featured Works]"}
        </Text>
        <Stagger
          style={{
            alignItems: "end",
            height: isMobile ? "" : "8%",
          }}
          visible={true}
          initial={{
            opacity: 0,
            translateY: 100,
          }}
          animate={{
            translateY: 0,
            opacity: 1,

            transition: {
              type: "spring",
              damping: 100,
              stagger: {
                offset: 50,
              },
            },
          }}
        >
          {data.map((item, i) => (
            <Pressable
              onHoverIn={() => {
                setHoveredIndex(i);
              }}
              onHoverOut={() => {
                setHoveredIndex("");
              }}
              onPress={() => handlePress(item, i)}
              key={item.id}
            >
              <ProjectCard item={item} i={i} hoveredIndex={hoveredIndex} />
            </Pressable>
          ))}
        </Stagger>
        <View h={spacing} />
      </ScrollView>
      {/* >style opacity nonsense */}
      {!isMobile && (
        <>
          <Box
            opacity={0.7}
            bg={colorMode === "light" ? "#F9F9F8" : "rgb(20, 20, 20)"}
            position={"fixed"}
            top={0}
            left={0}
            h="8%"
            w="100vw"
          />
          <Box
            opacity={0.7}
            bg={colorMode === "light" ? "#F9F9F8" : "rgb(20, 20, 20)"}
            position={"fixed"}
            bottom={0}
            left={0}
            h="8%"
            w="100vw"
          />
        </>
      )}
    </HStack>
  );
};

export default WorkScreen;

const ProjectCard = React.memo(({ item, i, hoveredIndex }) => {
  return (
    <PresenceTransition
      visible={hoveredIndex === i} // trigger animation when we hover over index
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
      }}
      initial={{
        opacity: 1,
      }}
      animate={{
        opacity: 0.3,
        transition: {
          type: "timing",
          duration: 300,
        },
      }}
    >
      <Text variant={"tagtext"}>{`[${item.year}]`}</Text>
      <Text variant={"projtext"} color={hoveredIndex === i && "muted.400"}>
        {item.title}
      </Text>
    </PresenceTransition>
  );
});
