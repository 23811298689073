import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { extendTheme, NativeBaseProvider } from "native-base";
import { theme } from "./theme";
import AnimatedCursor from "react-animated-cursor";
import AnimatedBackground from "./components/AnimatedBackground";
import { LoadingAnim } from "./components/LoadingAnim";

const root = ReactDOM.createRoot(document.getElementById("root"));
const nativeBaseTheme = extendTheme(theme);

root.render(
  <NativeBaseProvider theme={nativeBaseTheme}>
    <AnimatedCursor
      color={"150, 150, 150"}
      innerSize={3}
      outerSize={18}
      trailingSpeed={8}
      innerScale={1}
      outerScale={2}
      outerAlpha={0}
      outerStyle={{
        border: `1px solid rgb(150, 150, 150)`,
      }}
    />

    <LoadingAnim />
    <AnimatedBackground>
      <App />
    </AnimatedBackground>
  </NativeBaseProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
