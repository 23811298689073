import { Icon } from "native-base";
import { Circle, G, Path, Polyline } from "react-native-svg";

const BackIcon = ({ size }) => (
  <Icon fill="#000000" size={size} viewBox="0 0 32 32">
    <path d="M12.061 16l9.471-9.47c0.134-0.136 0.218-0.322 0.218-0.528 0-0.415-0.336-0.751-0.751-0.751-0.207 0-0.394 0.083-0.529 0.218l-9.999 10c-0.136 0.136-0.22 0.323-0.22 0.53s0.084 0.395 0.22 0.53l9.999 10.001c0.136 0.136 0.324 0.22 0.531 0.22 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.084-0.395-0.22-0.531v0z"></path>
  </Icon>
);

const PauseIcon = ({ size }) => (
  <Icon fill="#000000" size={size} viewBox="0 0 256 256">
    <path
      d="M46.677 64.652c0-9.362 7.132-17.387 16.447-17.394 9.315-.007 24.677.007 34.55.007 9.875 0 17.138 7.594 17.138 16.998 0 9.403-.083 119.094-.083 127.82 0 8.726-7.58 16.895-16.554 16.837-8.975-.058-25.349.115-34.963.058-9.614-.058-16.646-7.74-16.646-17.254 0-9.515.11-117.71.11-127.072zm14.759.818s-.09 118.144-.09 123.691c0 5.547 3.124 5.315 6.481 5.832 3.358.518 21.454.47 24.402.47 2.947 0 7.085-1.658 7.167-6.14.08-4.483-.082-119.507-.082-123.249 0-3.742-4.299-4.264-7.085-4.66-2.787-.395-25.796 0-25.796 0l-4.997 4.056zm76.664-.793c.027-9.804 7.518-17.541 17.125-17.689 9.606-.147 25.283.148 35.004.148 9.72 0 17.397 8.52 17.397 17.77s-.178 117.809-.178 127c0 9.192-7.664 17.12-16.323 17.072-8.66-.05-26.354 0-34.991.048-8.638.05-17.98-8.582-18.007-17.783-.027-9.201-.055-116.763-.027-126.566zm16.917.554s-.089 118.145-.089 123.692c0 5.547 3.123 5.314 6.48 5.832 3.359.518 21.455.47 24.402.47 2.948 0 7.086-1.659 7.167-6.141.081-4.482-.08-119.506-.08-123.248 0-3.742-4.3-4.265-7.087-4.66-2.786-.396-25.796 0-25.796 0l-4.997 4.055z"
      fill-rule="evenodd"
    />
  </Icon>
);

const PlayIcon = ({ size }) => (
  <Icon fill="#000000" size={size} viewBox="0 0 256 256">
    <path
      d="M59 61.922c0-9.768 13.016-15.432 22.352-11.615 10.695 7.017 101.643 58.238 109.869 65.076 8.226 6.838 10.585 17.695-.559 25.77-11.143 8.074-99.712 60.203-109.31 64.73-9.6 4.526-21.952-1.632-22.352-13.088-.4-11.456 0-121.106 0-130.873zm13.437 8.48c0 2.494-.076 112.852-.216 115.122-.23 3.723 3 7.464 7.5 5.245 4.5-2.22 97.522-57.704 101.216-59.141 3.695-1.438 3.45-5.1 0-7.388C177.488 121.952 82.77 67.76 80 65.38c-2.77-2.381-7.563 1.193-7.563 5.023z"
      stroke="#979797"
      fill-rule="evenodd"
    />
  </Icon>
);

const SkipIcon = ({ size }) => (
  <Icon size={size} viewBox="0 0 32 32">
    <path
      class="sketchy_een"
      d="M29.998,16.648c0.004-0.073,0.002-0.143-0.015-0.213c-0.004-0.024,0.001-0.048-0.005-0.071
	c-0.012-0.043-0.038-0.071-0.056-0.107c-0.006-0.012-0.006-0.025-0.013-0.037c-0.004-0.007-0.012-0.011-0.016-0.019
	c-0.065-0.105-0.146-0.194-0.252-0.276c-0.229-0.173-0.457-0.345-0.687-0.516c-0.575-0.451-1.11-0.942-1.645-1.437
	c-0.58-0.538-1.191-1.039-1.798-1.545c-0.605-0.504-1.233-0.984-1.834-1.492c-0.623-0.528-1.235-1.068-1.842-1.616
	c-0.56-0.506-1.11-1.03-1.697-1.505c-0.646-0.522-1.307-1.022-1.971-1.522c-0.636-0.501-1.261-1.015-1.878-1.539
	c-0.169-0.141-0.341-0.234-0.568-0.234c-0.21,0-0.417,0.085-0.566,0.234c-0.149,0.149-0.234,0.356-0.234,0.566
	c0,0.128,0.046,0.264,0.112,0.387c-0.124,0.147-0.208,0.329-0.208,0.532c0.006,1.479-0.006,2.958-0.03,4.436
	c-0.012,0.651-0.01,1.3-0.006,1.953c0.003,0.392,0.011,0.785,0.014,1.178c-0.689-0.514-1.376-1.03-2.06-1.554
	c-0.683-0.54-1.352-1.1-2.037-1.638c-0.673-0.528-1.346-1.054-1.999-1.61c-0.705-0.599-1.391-1.221-2.114-1.8
	C5.899,6.646,5.212,6.078,4.51,5.53C4.202,5.292,3.896,5.054,3.589,4.818c-0.111-0.085-0.27-0.119-0.422-0.119
	c-0.088,0-0.174,0.012-0.247,0.032C2.701,4.79,2.515,4.935,2.4,5.129c-0.035,0.06-0.047,0.129-0.066,0.195
	C2.202,5.473,2.116,5.665,2.106,5.878C1.965,9.369,2.005,12.865,2.005,16.36c0,1.61-0.002,3.222,0.018,4.832
	c0.012,0.818,0.018,1.638,0.016,2.455c-0.002,0.776-0.02,1.554-0.014,2.33c0.004,0.419,0.333,0.761,0.744,0.798
	c0.067,0.042,0.134,0.085,0.211,0.106c0.197,0.053,0.465,0.042,0.635-0.084c0.748-0.542,1.495-1.084,2.245-1.62
	c0.703-0.504,1.38-1.038,2.084-1.536c1.411-1,2.884-1.905,4.295-2.902c0.735-0.518,1.467-1.039,2.211-1.544
	c-0.051,1.055-0.085,2.11-0.101,3.166c-0.02,1.251-0.038,2.499-0.038,3.749c0,0.272,0.146,0.503,0.354,0.653
	c0.01,0.114,0.039,0.227,0.098,0.326c0.202,0.347,0.717,0.522,1.068,0.28c1.344-0.923,2.658-1.888,3.968-2.856
	c0.619-0.456,1.243-0.905,1.864-1.36c0.637-0.469,1.264-0.949,1.906-1.413c1.034-0.748,2.062-1.507,3.083-2.273
	c0.482-0.362,0.965-0.725,1.449-1.084c0.23-0.17,0.457-0.347,0.691-0.516c0.28-0.203,0.568-0.397,0.844-0.605
	c0.167-0.123,0.294-0.246,0.351-0.455c0.013-0.049,0.007-0.099,0.01-0.149C29.998,16.654,29.998,16.651,29.998,16.648z
	 M12.004,18.843c-1.386,0.989-2.787,1.951-4.202,2.898C6.97,22.299,6.19,22.923,5.388,23.52c-0.574,0.428-1.162,0.839-1.749,1.251
	c-0.007-1.194-0.013-2.387-0.028-3.579c-0.018-1.61-0.008-3.222-0.006-4.832c0.004-1.671,0.006-3.343,0.004-5.014
	c0-1.421,0.05-2.839,0.11-4.256C4.31,7.551,4.902,8.011,5.486,8.48c0.703,0.564,1.358,1.181,2.042,1.765
	c0.661,0.562,1.334,1.108,2.013,1.646c0.703,0.557,1.386,1.143,2.094,1.693c1.002,0.777,2.025,1.525,3.032,2.296
	c0.244,0.191,0.484,0.386,0.726,0.58c-0.108,0.078-0.213,0.16-0.325,0.235c-0.341,0.23-0.679,0.466-1.014,0.706
	C13.376,17.887,12.685,18.359,12.004,18.843z M25.18,18.716c-1.251,0.945-2.513,1.872-3.779,2.792
	c-1.012,0.739-2.033,1.465-3.045,2.206c-0.788,0.575-1.598,1.122-2.404,1.673c0.004-1.008,0.008-2.017,0.016-3.027
	c0.006-0.623,0.006-1.246,0.03-1.87c0.028-0.707,0.083-1.411,0.129-2.118c0.006-0.102-0.016-0.199-0.052-0.29
	c0.301-0.207,0.6-0.417,0.903-0.623c0.193-0.131,0.337-0.292,0.401-0.522c0.008-0.03,0.001-0.061,0.006-0.091
	c0.097-0.144,0.165-0.307,0.165-0.483c0-0.157-0.042-0.306-0.121-0.443c-0.077-0.131-0.183-0.236-0.314-0.316
	c-0.076-0.058-0.153-0.116-0.229-0.173c-0.147-0.116-0.292-0.235-0.438-0.354c0.044-0.102,0.067-0.213,0.063-0.329
	c-0.024-0.699-0.018-1.399-0.032-2.098c-0.012-0.659-0.022-1.318-0.024-1.977c-0.003-1.238,0.033-2.476,0.049-3.714
	c0.2,0.155,0.393,0.32,0.595,0.473c0.54,0.405,1.09,0.796,1.631,1.202c1.227,0.986,2.356,2.089,3.56,3.103
	c1.237,1.043,2.527,2.021,3.744,3.089c0.431,0.379,0.846,0.776,1.284,1.147c0.239,0.201,0.486,0.392,0.734,0.581
	c-0.352,0.261-0.704,0.522-1.053,0.786C26.393,17.797,25.788,18.258,25.18,18.716z"
    />
  </Icon>
);

const VibrateIcon = ({ size }) => (
  <Icon
    fill="#000000"
    size={"100%"}
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path d="M159.99951,36h-64a20.02229,20.02229,0,0,0-20,20V200a20.02229,20.02229,0,0,0,20,20h64a20.02229,20.02229,0,0,0,20-20V56A20.02229,20.02229,0,0,0,159.99951,36Zm12,164a12.01312,12.01312,0,0,1-12,12h-64a12.01312,12.01312,0,0,1-12-12V56a12.01312,12.01312,0,0,1,12-12h64a12.01312,12.01312,0,0,1,12,12ZM212.00244,88v80a4,4,0,0,1-8,0V88a4,4,0,1,1,8,0Zm32,16v48a4,4,0,0,1-8,0V104a4,4,0,1,1,8,0ZM51.99951,88v80a4,4,0,0,1-8,0V88a4,4,0,0,1,8,0Zm-32,16v48a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z" />
  </Icon>
);

const TimeIcon = ({ size, fill }) => (
  <Icon
    size={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    class="bi bi-stopwatch"
  >
    <Path
      d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"
      fill={fill}
    />
    <Path
      d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"
      fill={fill}
    />
  </Icon>
);

const IntervalIcon = ({ size, fill }) => (
  <Icon
    fill={fill}
    size={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M4.70710678,18 L6.85355339,20.1464466 C7.04881554,20.3417088 7.04881554,20.6582912 6.85355339,20.8535534 C6.65829124,21.0488155 6.34170876,21.0488155 6.14644661,20.8535534 L3.14644661,17.8535534 C2.95118446,17.6582912 2.95118446,17.3417088 3.14644661,17.1464466 L6.14644661,14.1464466 C6.34170876,13.9511845 6.65829124,13.9511845 6.85355339,14.1464466 C7.04881554,14.3417088 7.04881554,14.6582912 6.85355339,14.8535534 L4.70710678,17 L18.5,17 C19.3284271,17 20,16.3284271 20,15.5 L20,12.5 C20,12.2238576 20.2238576,12 20.5,12 C20.7761424,12 21,12.2238576 21,12.5 L21,15.5 C21,16.8807119 19.8807119,18 18.5,18 L4.70710678,18 Z M19.2928932,7 L17.1464466,4.85355339 C16.9511845,4.65829124 16.9511845,4.34170876 17.1464466,4.14644661 C17.3417088,3.95118446 17.6582912,3.95118446 17.8535534,4.14644661 L20.8535534,7.14644661 C21.0488155,7.34170876 21.0488155,7.65829124 20.8535534,7.85355339 L17.8535534,10.8535534 C17.6582912,11.0488155 17.3417088,11.0488155 17.1464466,10.8535534 C16.9511845,10.6582912 16.9511845,10.3417088 17.1464466,10.1464466 L19.2928932,8 L5.5,8 C4.67157288,8 4,8.67157288 4,9.5 L4,12.5 C4,12.7761424 3.77614237,13 3.5,13 C3.22385763,13 3,12.7761424 3,12.5 L3,9.5 C3,8.11928813 4.11928813,7 5.5,7 L19.2928932,7 Z"
      fill={fill}
    />
  </Icon>
);

const DateIcon = ({ size }) => (
  <Icon
    size={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="6" width="18" height="15" rx="2" stroke="#222222" />
    <path
      d="M3 10C3 8.11438 3 7.17157 3.58579 6.58579C4.17157 6 5.11438 6 7 6H17C18.8856 6 19.8284 6 20.4142 6.58579C21 7.17157 21 8.11438 21 10H3Z"
      fill="#222222"
    />
    <path d="M7 3L7 6" stroke="#222222" stroke-linecap="round" />
    <path d="M17 3L17 6" stroke="#222222" stroke-linecap="round" />
  </Icon>
);

const HomeIcon = ({ size }) => (
  <Icon
    size={size}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.772 16.043l-15.012-15.724c-0.189-0.197-0.449-0.307-0.721-0.307s-0.533 0.111-0.722 0.307l-15.089 15.724c-0.383 0.398-0.369 1.031 0.029 1.414 0.399 0.382 1.031 0.371 1.414-0.029l1.344-1.401v14.963c0 0.552 0.448 1 1 1h6.986c0.551 0 0.998-0.445 1-0.997l0.031-9.989h7.969v9.986c0 0.552 0.448 1 1 1h6.983c0.552 0 1-0.448 1-1v-14.968l1.343 1.407c0.197 0.204 0.459 0.308 0.722 0.308 0.249 0 0.499-0.092 0.692-0.279 0.398-0.382 0.411-1.015 0.029-1.413zM26.985 14.213v15.776h-4.983v-9.986c0-0.552-0.448-1-1-1h-9.965c-0.551 0-0.998 0.445-1 0.997l-0.031 9.989h-4.989v-15.777c0-0.082-0.013-0.162-0.032-0.239l11.055-11.52 10.982 11.507c-0.021 0.081-0.036 0.165-0.036 0.252z"
      fill="black"
    />
  </Icon>
);

const LandscapeIcon = ({ size }) => {
  return (
    <Icon size={size} viewBox="0 0 122.88 106.43">
      <g>
        <path
          class="st0"
          d="M11.1,0h35.63c3.05,0,5.85,1.25,7.85,3.25c2.03,2.03,3.25,4.8,3.25,7.85v31.46h-3.19V12.18H3.15v75.26l0,0 h7.61v11.61c0,1.58,0.27,3.1,0.77,4.51H11.1c-3.05,0-5.85-1.25-7.85-3.25C1.22,98.27,0,95.51,0,92.45V11.1 c0-3.05,1.25-5.85,3.25-7.85C5.28,1.22,8.04,0,11.1,0L11.1,0L11.1,0z M100.09,30.42c0.75-0.86,2.06-0.95,2.91-0.19 c0.86,0.75,0.95,2.06,0.19,2.91l-5.89,6.71c-0.74,0.85-2.02,0.94-2.88,0.22l0,0l-6.85-5.72c-0.88-0.73-0.99-2.04-0.26-2.91 c0.73-0.88,2.04-0.99,2.91-0.26l3.01,2.51c-0.82-3.89-2.71-7.43-5.48-10.2c-3.58-3.59-8.66-5.91-14.85-6.1 c-1.14-0.03-2.04-0.98-2.01-2.12s0.98-2.04,2.12-2.01c7.33,0.22,13.37,3.01,17.67,7.31c3.48,3.49,5.8,7.98,6.71,12.9L100.09,30.42 L100.09,30.42z M122.88,59.7v35.63c0,3.05-1.25,5.85-3.25,7.85c-2.03,2.03-4.8,3.25-7.85,3.25h-78.9c-3.05,0-5.85-1.25-7.85-3.25 c-2.03-2.03-3.25-4.8-3.25-7.85V59.7c0-3.05,1.25-5.85,3.25-7.85c2.03-2.03,4.79-3.25,7.85-3.25h78.9c3.05,0,5.85,1.25,7.85,3.25 C121.66,53.88,122.88,56.64,122.88,59.7L122.88,59.7L122.88,59.7z M35.41,77.49c0,2.51-2.03,4.57-4.57,4.57 c-2.51,0-4.57-2.03-4.57-4.57c0-2.51,2.03-4.57,4.57-4.57C33.36,72.92,35.41,74.95,35.41,77.49L35.41,77.49L35.41,77.49z M37.88,51.75v51.49h72.82V51.75H37.88L37.88,51.75z"
        />
      </g>
    </Icon>
  );
};
const CodeIcon = ({ size }) => {
  return (
    <Icon size={size} viewBox="0 0 32 32">
      <Path
        d="M7.11,23.52l1.42-1.41C5.14,18.72,3.37,17,2.39,16L8.53,9.89A1,1,0,1,0,7.11,8.47L.29,15.29A1.05,1.05,0,0,0,0,16a1,1,0,0,0,.3.71Z"
        fill={"grey"}
      />
      <Path
        d="M31.71,15.29c-.1-.09-6.82-6.82-6.82-6.82L23.48,9.89c3.38,3.38,5.15,5.16,6.13,6.09l-6.13,6.13a1,1,0,0,0,.7,1.71,1,1,0,0,0,.71-.3l6.82-6.81A1,1,0,0,0,32,16,1,1,0,0,0,31.71,15.29Z"
        fill={"grey"}
      />
      <Path
        d="M13.27,27.91a1,1,0,0,0,1-.76L19.7,5.33a1,1,0,0,0-1.94-.48L12.3,26.67A1,1,0,0,0,13,27.88,1,1,0,0,0,13.27,27.91Z"
        fill={"grey"}
      />
    </Icon>
  );
};

export {
  BackIcon,
  PlayIcon,
  PauseIcon,
  SkipIcon,
  LandscapeIcon,
  VibrateIcon,
  CodeIcon,
  TimeIcon,
  HomeIcon,
  IntervalIcon,
  DateIcon,
};
